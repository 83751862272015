'use client'
import { useEffect, useState } from 'react'
import { Transition, TransitionChild } from '@headlessui/react'
import clsx from 'clsx'
import Image, { type StaticImageData } from 'next/image'

import image0 from './images/pill-odds-0.png'
import image1 from './images/pill-odds-1.png'
import image2 from './images/pill-odds-2.png'


const items: StaticImageData[] = [
  image0,
  image1,
  image2,
]

const lastIndex = items.length - 1

type PillCarouselProps = {
  className?: string
}

const PillCarousel: React.FC<PillCarouselProps> = (props) => {
  const { className } = props

  const [ activeIndex, setActiveIndex ] = useState(0)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveIndex((value) => value === lastIndex ? 0 : value + 1)
    }, 2000)

    return () => {
      clearInterval(timerId)
    }
  }, [ activeIndex ])

  const itemClassName = 'absolute top-0 left-0 size-full'

  return (
    <div className={clsx(className, 'relative')}>
      {
        items.map((image, index) => {
          const nextItem = items[index === lastIndex ? 0 : index + 1]

          const isActive = index === activeIndex

          return (
            <Transition
              as="div"
              className="size-full"
              show={isActive}
              key={image.src}
            >
              <div className={itemClassName}>
                <TransitionChild
                  as="div"
                  className="size-full will-change-transform sm:transform-gpu transition duration-500 ease-over-bounce"
                  enterFrom="translate-y-full opacity-0"
                  leaveTo="-translate-y-full opacity-0 sm:opacity-30"
                >
                  <div className="size-full flex items-center justify-center overflow-hidden">
                    <Image
                      className="w-full h-auto max-w-none"
                      src={image}
                      alt=""
                    />
                  </div>
                </TransitionChild>
                <TransitionChild
                  as="div"
                  className="size-full will-change-transform sm:transform-gpu transition duration-500 ease-over-bounce"
                  enterFrom="translate-y-full opacity-0"
                  enterTo="translate-y-0 opacity-30"
                  leaveFrom="translate-y-0 opacity-30"
                  leaveTo="-translate-y-full opacity-0"
                >
                  <div className="size-full flex items-center justify-center overflow-hidden">
                    <Image
                      className="w-full h-auto max-w-none"
                      src={nextItem}
                      alt=""
                    />
                  </div>
                </TransitionChild>
              </div>
            </Transition>
          )
        })
      }
    </div>
  )
}

export default PillCarousel
