'use client'
import { Transition, TransitionChild } from '@headlessui/react'
import Image, { type StaticImageData } from 'next/image'


export type Item = {
  image: StaticImageData
  title: string
}

type ItemsRightCarouselProps = {
  activeIndex: number
  items: Item[]
}

const ItemsRightCarousel: React.FC<ItemsRightCarouselProps> = (props) => {
  const { activeIndex, items } = props

  const lastIndex = items.length - 1
  const itemClassName = 'absolute top-0 -sm:left-0 sm:right-2 sm:-translate-y-1/2 mask-gradient-to-t from-transparent from-30% to-black to-40% sm:stops-4-cylinder'

  return (
    <div className="relative w-full flex-1 leading-[0.7] sm:leading-none">
      {
        items.map((item, index) => {
          const currentItem = item
          const prevItem = items[index === 0 ? lastIndex : index - 1]
          const nextItem = items[index === lastIndex ? 0 : index + 1]

          const isActive = index === activeIndex

          return (
            <Transition
              show={isActive}
              key={currentItem.title}
            >
              <div className={itemClassName}>
                <TransitionChild
                  as="div"
                  className="will-change-transform sm:transform-gpu"
                  enter="transition ease-linear duration-500"
                  enterFrom="translate-y-full opacity-50"
                  enterTo="translate-y-0 opacity-30"
                  leave="transition ease-linear duration-500"
                  leaveFrom="translate-y-0 opacity-30"
                  leaveTo="-translate-y-full opacity-0"
                >
                  <div className="hidden sm:block w-full opacity-30 text-white sm:text-right">
                    {prevItem.title}
                  </div>
                </TransitionChild>
                <TransitionChild
                  as="div"
                  className="will-change-transform sm:transform-gpu"
                  enter="transition ease-linear duration-500"
                  enterFrom="translate-y-full opacity-0"
                  enterTo="translate-y-0 opacity-100"
                  leave="transition ease-linear duration-500"
                  leaveFrom="translate-y-0 opacity-100"
                  leaveTo="-translate-y-full opacity-0 sm:opacity-30"
                >
                  <div className="flex items-center justify-end -sm:flex-row-reverse">
                    <img
                      className="flex-none size-[9vw] sm:size-[6vw]"
                      src={currentItem.image.src}
                      width={112}
                      height={112}
                      alt=""
                    />
                    <div className="text-white">
                      {currentItem.title}
                    </div>
                  </div>
                </TransitionChild>
                <TransitionChild
                  as="div"
                  className="will-change-transform sm:transform-gpu"
                  enter="transition ease-linear duration-500"
                  enterFrom="translate-y-full opacity-0"
                  enterTo="translate-y-0 opacity-30"
                  leave="transition ease-linear duration-500"
                  leaveFrom="translate-y-0 opacity-30"
                  leaveTo="-translate-y-full opacity-0"
                >
                  <div className="w-full opacity-30 text-white sm:text-right">
                    {nextItem.title}
                  </div>
                </TransitionChild>
              </div>
            </Transition>
          )
        })
      }
    </div>
  )
}

export default ItemsRightCarousel
