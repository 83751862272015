'use client'
import { Transition, TransitionChild } from '@headlessui/react'


export type LeftPrefix = {
  title: string
  relatedItemIndexes: number[]
}

type ItemsLeftPrefixCarouselProps = {
  activeIndex: number
  items: LeftPrefix[]
}

const ItemsLeftPrefixCarousel: React.FC<ItemsLeftPrefixCarouselProps> = (props) => {
  const { activeIndex, items } = props

  const lastIndex = items.length - 1
  const itemClassName = 'absolute top-0 left-0 sm:-translate-y-1/2 mask-gradient-to-t from-black via-black from-50% via-70% to-85% sm:to-95%'

  return (
    <div className="relative w-full flex-1 leading-[0.7] sm:leading-none">
      {
        items.map((item, index) => {
          const currentItem = item
          const prevItem = items[index === 0 ? lastIndex : index - 1]

          const isActive = currentItem.relatedItemIndexes.includes(activeIndex)

          return (
            <Transition
              show={isActive}
              key={currentItem.title}
            >
              <div className={itemClassName}>
                <TransitionChild
                  as="div"
                  className="will-change-transform sm:transform-gpu"
                  enter="transition ease-linear duration-500"
                  enterFrom="translate-y-full opacity-50"
                  enterTo="translate-y-0 opacity-30"
                  leave="transition ease-linear duration-500"
                  leaveFrom="translate-y-0 opacity-30"
                  leaveTo="-translate-y-full opacity-0"
                >
                  <div className="w-full opacity-30 text-white">
                    {prevItem.title}
                  </div>
                </TransitionChild>
                <TransitionChild
                  as="div"
                  className="my-1 sm:m-0 will-change-transform sm:transform-gpu"
                  enter="transition ease-linear duration-500"
                  enterFrom="translate-y-full opacity-0"
                  enterTo="translate-y-0 opacity-100"
                  leave="transition ease-linear duration-500"
                  leaveFrom="translate-y-0 opacity-100"
                  leaveTo="-translate-y-full opacity-0 sm:opacity-30"
                >
                  <div className="text-white mb-[1em]">
                    {currentItem.title}
                  </div>
                </TransitionChild>
              </div>
            </Transition>
          )
        })
      }
    </div>
  )
}

export default ItemsLeftPrefixCarousel
